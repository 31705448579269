<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">招聘模块</a>
          <i>></i>
          <a href="javascript:;" @click="pageBack">人才库</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">{{userName}} -- 项目经历</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start; ">
          <div class="searchbox" style="margin-bottom:5px">
            <div class="btnBox" style="margin-left:20px">
<!--              <el-button style="margin-left:20px" class="bgc-bv" round @click="getData()">查询</el-button>-->
              <el-button style="margin-left:20px" class="bgc-bv" round @click="showUpdateData()">新增项目经历</el-button>
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
                ref="table"
                :data="tableData"
                :height="tableHeight"
                size="small"
                tooltip-effect="dark"
                style="width: 100%"
                :header-cell-style="tableHeader"
                stripe
            >
              <el-table-column
                  label="序号"
                  align="center"
                  type="index"
                  width="100px"
                  :index="indexMethod"
              />
              <el-table-column
                  label="项目名称"
                  align="left"
                  prop="itemName"
                  show-overflow-tooltip
                  min-width="100"
              />
              <el-table-column
                  label="项目角色"
                  align="left"
                  prop="itemRole"
                  show-overflow-tooltip
                  min-width="100"
              />
              <el-table-column
                  label="项目时间"
                  align="left"
                  prop="mobile"
                  show-overflow-tooltip
                  min-width="160"
              >
                <template slot-scope="{ row }">
                  <template v-if="row.beginTime&&row.endTime">
                    {{row.beginTime.substring(0,10)}} ~ {{row.endTime.substring(0,10)}}

                  </template>
                  <template v-else>--</template>
                </template>
              </el-table-column>
              <el-table-column
                  label="项目描述"
                  align="left"
                  prop="description"
                  show-overflow-tooltip
                  min-width="100"
              />
              <el-table-column
                  label="项目业绩"
                  align="left"
                  prop="performance"
                  show-overflow-tooltip
                  min-width="100"
              />
              <el-table-column label="操作" align="center" width="240px" fixed="right">
                <div slot-scope="{ row }">
                  <el-button type="text"
                      style="padding:0px 5px"
                      size="mini"
                      @click="showUpdateData(row)"
                  >编辑</el-button>
                  <el-button
                      type="text"
                      style="padding:0px 5px"
                      size="mini"
                      @click="deleteData(row.itemExperienceId)"
                  >删除</el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
    <el-dialog
        :title="dialogTitle"
        :visible.sync="dialogVisible"
        width="60%"
        :before-close="handleClose">
      <div>
<!--        <h3 style="padding: 0 0 20px 0;">沟通记录</h3>-->
        <el-form
            :model="dataForm"
            ref="dataForm"
            :rules="dataRules"
            label-width="150px"
        >
                <el-form-item label="项目名称：" prop="itemName">
                  <el-input v-model="dataForm.itemName" size="small" placeholder="请输入项目名称"></el-input>
                </el-form-item>
                <el-form-item label="项目角色：" prop="itemRole">
                  <el-input v-model="dataForm.itemRole" size="small" placeholder="请输入项目角色"></el-input>
                </el-form-item>
                <el-form-item label="项目时间：" prop="itemTime">
                  <el-date-picker
                      v-model="dataForm.itemTime"
                      type="daterange"
                      size="small"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      format="yyyy/MM/dd"
                      value-format="yyyy/MM/dd">
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="项目描述：" prop="description">
                  <el-input
                      type="textarea"
                      :autosize="{ minRows: 6, maxRows: 10}"
                      v-model="dataForm.description"
                      placeholder="请输入项目描述"
                      maxlength="2000"
                      show-word-limit>
                  </el-input>
                </el-form-item>
                <el-form-item label="项目业绩：" prop="performance">
                  <el-input
                      type="textarea"
                      :autosize="{ minRows: 4, maxRows: 8}"
                      v-model="dataForm.performance"
                      placeholder="请输入项目业绩"
                      maxlength="500"
                      show-word-limit>
                  </el-input>
                </el-form-item>


        </el-form>
      </div>
      <span slot="footer" class="dialog-footer" style="width: 100%">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="saveData">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "projectExperience",
  components: {
    Empty,
    PageNum
  },
  mixins: [List],
  data() {
    return {
      talentId: this.$route.query.talentId,
      userName: this.$route.query.userName,

      areaList: [], // 期望城市List
      salaryRequireList: [], // 薪资要求List

      dialogTitle: '',
      dialogVisible: false, //新增编辑弹窗开关

      dataForm: {
        itemExperienceId: "",
        itemName: "",
        itemRole: "",
        itemTime: "",
        description: "",
        performance: "",
      },

      // itemRoleList: [], // 项目角色List

      dataRules: {
        itemName: [{ required: true, message: "请输入项目名称", trigger: "blur" }],
      },
      retrievalData:{},
      currentPage:''
    };
  },
  computed: {},
  created() {
    if(this.$route.query.retrievalData){
      this.retrievalData = this.$route.query.retrievalData
    }
    if(this.$route.query.currentPage){
      this.currentPage = this.$route.query.currentPage
    }
    // this.getDropDownData()
  },
  methods: {
    // 返回上一页
    pageBack(){
      this.$router.push({
        path: "/hr/recruitModule/personnelLibrary/personnelList",
        query: {
          retrievalData: this.retrievalData,
          currentPage: this.currentPage
        },
      });
    },
    // 获取 all- 下拉数据
    // getDropDownData() {
    //  // 薪资要求list
    //   const salaryRequireList = this.$setDictionary(
    //       "MINI_JOB_SERVICE_SALARY",
    //       "list"
    //   );
    //   for (const key in salaryRequireList) {
    //     this.salaryRequireList.push({
    //       value: key,
    //       label: salaryRequireList[key],
    //     });
    //   }
    // },
    // 获取列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        talentId: this.talentId
      };
      this.doFetch({
        url: "/hr/talent/info/itemExperienceLogPage",
        params,
        pageNum
      });
    },
    // 打开新增&编辑
    showUpdateData(row){
      this.dialogVisible = true
      let str = '项目经历'
      this.$nextTick(()=>{
        this.$refs["dataForm"].resetFields();
        this.dataForm = {
          itemExperienceId: "",
          itemName: "",
          itemRole: "",
          itemTime: "",
          description: "",
          performance: "",
        }
        if(row){
          this.dialogTitle = '编辑' + str
          this.dataForm = {
            ...row
          }
          if(row.beginTime&&row.endTime){
            this.dataForm.itemTime = [ row.beginTime.substring(0,10), row.endTime.substring(0,10) ]
          }
          this.dataForm.itemExperienceId = row.itemExperienceId
        }else{
          this.dialogTitle = '新增' + str
          this.dataForm.itemExperienceId = ""
        }
      })
    },
    // 关闭新增&编辑
    handleClose() {
      this.dialogVisible = false
    },
    // 弹层保存数据
    saveData(){
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          let params = {
            talentId: this.talentId,
            itemName: this.dataForm.itemName,
          }
          if(this.dataForm.itemExperienceId != ""){
            params.itemExperienceId = this.dataForm.itemExperienceId
          }
          if(this.dataForm.itemRole){
            params.itemRole = this.dataForm.itemRole
          }
          if(this.dataForm.itemTime && this.dataForm.itemTime.length > 0){
            params.beginTime = this.dataForm.itemTime[0]
            params.endTime = this.dataForm.itemTime[1]
          }
          if(this.dataForm.description){
            params.description = this.dataForm.description
          }
          if(this.dataForm.performance){
            params.performance = this.dataForm.performance
          }
          console.log(params)
          this.$post(
              "/hr/talent/info/saveOrUpdateItemExperience",
              params
          ).then((res) => {
            if (res.status == 0) {
              this.$message({
                message: res.message,
                type: "success",
              });
              this.dialogVisible = false
              this.getData(-1);
            }
          });
        }
      })
    },
    // 删除列表数据
    deleteData(id){
      let str = '项目经历'
      this.$confirm(
          "是否删除此条" + str,
          "提示",
          {
            confirmButtonText: "确定删除",
            cancelButtonText: "取消",
            type: "warning",
          }
      )
          .then(() => {
            let params = {
              // talentId: this.talentId,
              itemExperienceId: id
            }
            console.log(params)
            this.$post(
                "/hr/talent/info/removeItemExperienceLog",
                params
            ).then((res) => {
              if (res.status == 0) {
                this.$message({
                  message: res.message,
                  type: "success",
                });
                this.getData(-1);
              }
            });
          })
          .catch(() => {
            return;
          });
    },

  },
  beforeRouteLeave: resetKeepAlive,
  watch: {}
};
</script>
<style lang="less" scoped>
.el-rate__icon {
  margin-right: 0;
}
</style>
